.widthControl {
  max-width: 1500px;
  min-width: 1024px;
}

/* Ultra-wide screens (1600px and up) */
@media (min-width: 1600px) {
  .widthControl {
    max-width: 2500px;
    min-width: 1024px;
  }
}

.topMenu {
  border-bottom: 0 !important;
}

.topMenu :global li .ant-menu-submenu-title {
}

.topMenu :global li.ant-menu-submenu-selected .ant-menu-submenu-title {
  /*background-color: white !important;*/
  /*padding-left: 0.5rem;*/
  /*padding-right: 0.5rem;*/
  /*padding-bottom: 0 !important;*/
  /*border-bottom: 0 !important;*/
}

.topMenu :global li.ant-menu-submenu-selected .ant-menu-submenu::after {
  all: unset;
}

/*.topMenu :global .ant-menu {*/
/*  background-color: rgb(250 250 250) !important;*/
/*  border-bottom-color: #eb5757 !important;*/
/*}*/
